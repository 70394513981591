'use client'
import { getMerchantFeatures } from '@jobmojito/admin-app/components/merchantFeatures/getMerchantFeatures'
import { HeaderMerchantProfile } from '@jobmojito/admin-app/types/collection'
import MenuLinkItem from '@jobmojito/admin-app/components/navigation/MenuLinks/MenuLinkItem'
import { usePathname } from 'next/navigation'
import React from 'react'

export default function MenuLinks({
  onClick,
  xMojito,
}: {
  onClick: () => void
  xMojito: HeaderMerchantProfile
}) {
  const pathname = usePathname()

  const merchantFeatures = getMerchantFeatures(
    xMojito?.merchant?.merchant_features,
  )

  const menuSections = [
    { id: 'main', title: '' },
    { id: 'interview', title: 'Interviews' },
    { id: 'white', title: 'Coaching portal' },
    { id: 'affiliate', title: 'Affiliate program' },
    { id: 'admin', title: 'Admin' },
  ]

  let menuItems = [{ href: '/', label: 'Dashboard', type: 'main' }]
  if (merchantFeatures.affiliate) {
    menuItems = [
      ...menuItems,
      {
        href: '/affiliate_promote_pages',
        label: 'Promote Pages',
        type: 'main',
      },
    ]
  }
  menuItems = [
    ...menuItems,
    { href: '/analytics', label: 'Analytics', type: 'main' },
  ]

  if (merchantFeatures.affiliate || merchantFeatures.consumer_portal) {
    if (
      xMojito?.profile?.type === 'admin' ||
      xMojito?.profile?.type === 'merchant_owner'
    ) {
      menuItems = [
        ...menuItems,
        { href: '/commission', label: 'Commission', type: 'main' },
      ]
    }
  }

  if (merchantFeatures.consumer_portal || merchantFeatures.interview_portal) {
    menuItems = [
      ...menuItems,
      {
        href: '/portal_customisation',
        label: 'Branding & Configuration',
        type: 'admin',
      },
    ]

    if (
      xMojito?.profile?.type === 'admin' ||
      xMojito?.profile?.type === 'merchant_owner'
    ) {
      menuItems = [
        ...menuItems,
        { href: '/admin_users', label: 'Admin Users', type: 'admin' },
      ]
    }
    if (
      merchantFeatures.interview_portal &&
      (xMojito?.profile?.type === 'admin' ||
        xMojito?.profile?.type === 'merchant_owner')
    ) {
      menuItems = [
        ...menuItems,
        {
          href: '/interview_subscription',
          label: 'Manage Payments',
          type: 'admin',
        },
      ]
    }
  }

  if (merchantFeatures.consumer_portal) {
    menuItems = [
      ...menuItems,
      { href: '/coaching_users', label: 'Users', type: 'white' },
    ]
    if (merchantFeatures.consumer_sponsorship) {
      menuItems = [
        ...menuItems,
        { href: '/sponsorship', label: 'Users Sponsorship', type: 'white' },
      ]
    }

    menuItems = [
      ...menuItems,
      { href: '/coaching_creator', label: 'Coaching Creator', type: 'white' },
      {
        href: '/coaching_catalogue',
        label: 'Coaching Catalogue',
        type: 'white',
      },
    ]

    if (
      !merchantFeatures.interview_portal &&
      merchantFeatures.consumer_sponsorship
    ) {
      menuItems = [
        ...menuItems,
        { href: '/avatars', label: 'Avatars', type: 'white' },
      ]
    }

    if (
      !merchantFeatures.interview_portal &&
      merchantFeatures.consumer_sponsorship
    ) {
      menuItems = [
        ...menuItems,
        {
          href: '/knowledge_base',
          label: 'Avatars knowledge base',
          type: 'white',
        },
      ]
    }
  }

  if (merchantFeatures.interview_portal) {
    menuItems = [
      ...menuItems,
      { href: '/avatars', label: 'Avatars', type: 'interview' },
    ]
    menuItems = [
      ...menuItems,
      {
        href: '/knowledge_base',
        label: 'Avatars knowledge base',
        type: 'interview',
      },
    ]
    menuItems = [
      ...menuItems,
      { href: '/candidates', label: 'Candidates', type: 'interview' },
      {
        href: '/interview_creator',
        label: 'Interview Creator',
        type: 'interview',
      },
      {
        href: '/interview_results',
        label: 'Interview Results',
        type: 'interview',
      },
    ]
  }

  /*
  return (
    <>
          {menuItems.map((link) => {
            const isActive =
            link.href === '/'
              ? pathname === link.href
              : pathname.startsWith(link.href)

              return (
                <MenuLinkItem 
                  isActive={isActive} key={link.href.replaceAll('/','')} href={link.href} onClick={onClick} >
                    {link.label}
                </MenuLinkItem>
              )
          })}
          </>
  )*/

  return (
    <>
      {menuSections.map((section) => {
        if (
          menuItems.find((element) => element.type === section.id) !== undefined
        ) {
          return (
            <React.Fragment key={section.id}>
              {section.title !== '' && (
                <div
                  key={'title-' + section.id}
                  className="mt-6 text-xs font-bold text-default-400"
                >
                  {section.title}
                </div>
              )}
              {menuItems.map((link) => {
                const isActive =
                  link.href === '/'
                    ? pathname === link.href
                    : pathname.startsWith(link.href)

                if (link.type === section.id) {
                  return (
                    <MenuLinkItem
                      isActive={isActive}
                      key={section.id + '-' + link.href.replaceAll('/', '')}
                      href={link.href}
                      onClick={onClick}
                    >
                      {link.label}
                    </MenuLinkItem>
                  )
                }
              })}
            </React.Fragment>
          )
        }
      })}
    </>
  )
}
