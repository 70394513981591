import { Button } from '@nextui-org/button'
import NextImage from 'next/image'
import imagePrep from './assets/jobmojito-job-preparation.png'

export function RegisterStep1({
  completedStep,
  subMerchant,
}: {
  completedStep: () => void
  subMerchant: boolean
}) {
  return (
    <>
      <div className="mt-2 mb-5 gap-5">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-10">
          <div className="col-span-2">
            <div className="text-xl text-black font-bold underline">
              <span className="font-light">Create</span> Job Preparation
              platform
            </div>
            <div className="mt-2 text-black">
              Launch a customized job preparation portal under your branding and
              domain. Let job seekers practice interviews, polish resumes, craft
              cover letters, enjoy interactive games, and earn commissions on
              every purchase.
            </div>

            <ul className="text-xs text-black list-disc ml-5 mt-5">
              <li>
                Utilize our powerful suite of tools: Job Coaching, Resume Check,
                Cover Letter Writer, and Trivia Game
              </li>
              <li>
                Enjoy integrated payment options for your visitors, complete
                branding capabilities, and monthly commission payouts
              </li>
              <li>Enhance your service offerings today!</li>
            </ul>

            <div className="mt-10">
              Please follow the onboarding checklist, and should you have any
              queries, don't hesitate to contact us via the{' '}
              <a
                href="https://jobmojito.com/support"
                target="_blank"
                className="underline text-primary"
              >
                support page
              </a>
              .
            </div>
          </div>
          <div className="hidden md:flex">
            <NextImage alt="Background Image" src={imagePrep} />
          </div>
        </div>
      </div>
      <Button
        color="primary"
        onClick={() => {
          completedStep()
        }}
      >
        Next
      </Button>
    </>
  )
}
