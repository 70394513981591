'use client'
import { NavbarItem } from '@nextui-org/navbar'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import React from 'react'

export default function MenuLinkItem({
  href,
  children,
  className = '',
  isActive = false,
  onClick = () => {},
}: {
  href: string
  children: React.ReactNode
  className?: string
  isActive?: boolean
  onClick?: () => void
}) {
  const router = useRouter()
  function handleLink(event: any, href: string) {
    event.preventDefault()
    onClick()
    router.push(href)
  }

  return (
    <Link
      className={'w-full px-3 text-gray-700 hover:text-default-500 hover:rounded-lg hover:bg-gray-100 p-1 ' + (isActive&&'bg-secondary text-white rounded-lg text-default-500') + className}
      href={href}
      onClick={() => handleLink(event, href)}
    >
      <NavbarItem className={'items-center flex text-sm'}>{children}</NavbarItem>
    </Link>
  )
}
