'use client'
import KpiCard from '@jobmojito/admin-app/components/home/kpiCard'
import { getMerchantFeatures } from '@jobmojito/admin-app/components/merchantFeatures/getMerchantFeatures'
import { HeaderMerchantProfile, KpiInterview } from '@jobmojito/admin-app/types/collection'
import getClientSupabase from '@jobmojito/app/utils/getClientSupabase'
import {
  Button,
  Divider,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@nextui-org/react'
import NextImage from 'next/image'
import { useState } from 'react'
import chevronDown from './MenuLinks/assets/chevron_down.svg'

export const dynamic = 'force-dynamic'

export default function MerchantDropdown({
  xMojito
}: {
  xMojito: HeaderMerchantProfile
}) {
  const merchantFeatures =  getMerchantFeatures(xMojito?.merchant?.merchant_features)
  const supabase = getClientSupabase();

  const [kpi, setKpi] = useState<KpiInterview|undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showPopover, setShowPopover] = useState<boolean>(false)

  function getCredits() {
    supabase.rpc('merchant_credits_interview', { in_merchant_id: xMojito.merchant.id })
    .then((res) => {
      if (res.data !== null) {
          setKpi(res.data as KpiInterview);
      }
    })
  }

  function setDemoDataOff() {
    setIsLoading(true);
    supabase
    .from('merchant')
    .update({ demo_show_data: false })
    .eq('id', xMojito.merchant.id)
    .then((res) => {
      window.location.reload();
      setIsLoading(false);
    })
  }

  return (
    (merchantFeatures.consumer_portal || merchantFeatures.interview_portal || (merchantFeatures.affiliate && xMojito.merchant.demo_show_data)) && (xMojito.profile.type==='merchant_owner' || xMojito.profile.type==='admin') ?
    <Popover showArrow placement="bottom" backdrop='opaque' isOpen={showPopover} onOpenChange={(isOpen)=> { setShowPopover(isOpen); if((isOpen) && (merchantFeatures.interview_portal) && (kpi===undefined) && ((xMojito.merchant.interview_subscription_type === 'Career page') || (xMojito.merchant.interview_subscription_type === 'Avatar Interviews') || (xMojito.merchant.interview_subscription_type === 'Avatar Interviews PRO'))) { getCredits() } }}>
      <PopoverTrigger>
        <div className={'flex flex-row cursor-pointer'}>
            {xMojito.merchant.demo_show_data &&
              <div className={'whitespace-nowrap'}>
                <div className={'text-xs'}>Demo data</div>
                <div className='text-base font-bold text-warning'>visible</div>
              </div>
            }
            <div className={'ml-5 whitespace-nowrap'}>
              {(merchantFeatures.consumer_portal) &&
              <>
              <div className={'text-xs'}>Platform status</div>
              <div className='text-base font-bold text-black'>{xMojito.merchant.status}</div>
              </>
              }
              {(merchantFeatures.interview_portal && !(merchantFeatures.consumer_portal)) &&
              <>
              <div className={'text-xs'}>Subscription status</div>
              <div className={'text-base font-bold '+ (xMojito.merchant.interview_subscription_status==='trial'?'text-warning':(xMojito.merchant.interview_subscription_status==='canceled'?'text-danger':'text-black'))}>{xMojito.merchant.interview_subscription_status}</div>
              </>
              }
            </div>
            <NextImage
              src={chevronDown}
              alt={'dropdown'}
              className={'h-7 w-7 ms-1 mt-1 self-start'}
            />
        </div>
      </PopoverTrigger>
      <PopoverContent className="grid grid-cols-1 gap-4 p-6">
        <KpiCard title='Platform Status' value={xMojito.merchant.status} loaded type='string' firstLetterCapitalize />
        {xMojito.merchant.demo_show_data &&
          <>
          <Divider />
          <div className='flex flex-row gap-5 items-end'>
            <KpiCard title='Demo data' value='Visible' loaded/>
            <Button size='sm' color='secondary' variant='light' isLoading={isLoading} onClick={()=> { setDemoDataOff(); }}>Hide demo data</Button>
          </div>
          </>
        }

        {merchantFeatures.interview_portal &&
        <>
          <Divider />
          <KpiCard title='Subscription type' value={xMojito.merchant.interview_subscription_type} loaded type='string' />
          {xMojito.merchant.interview_subscription_status==='trial' &&
            <>
            <Divider /> 
            <KpiCard title='Trial until' value={xMojito.merchant.stripe_interview_subscription_end} loaded type='date' />
            </>
          }

          <Divider />
          <KpiCard title='Interview portal URL' size='md' value={xMojito.merchant.interview_frontend_domain} loaded type='link' />
          {kpi!==undefined && <>
            <Divider />
            <div className='flex flex-col gap-2'>
            {(kpi?.type==='Career page')?
            <>
                <KpiCard title={'Monthly Candidate credits'} color={(kpi?.credits_interview_monthly===null || kpi?.credits_interview_monthly===0)?'danger':'default'} value={(((kpi?.credits_interview_monthly===null)?0:kpi?.credits_interview_monthly)*2)+' / '+(((kpi?.limit_interview_monthly===null)?0:kpi?.limit_interview_monthly)*2)} loaded={kpi!==undefined} />
                <KpiCard title={'Extra Candidates credits'} value={(((kpi?.credits_interview_extra===null)?0:kpi?.credits_interview_extra)*2)} loaded={kpi!==undefined} />
            </>
            :(kpi?.type==='Avatar Interviews')?
            <>
                <KpiCard title={'Monthly Interview credits'} color={(kpi?.credits_interview_monthly===null || kpi?.credits_interview_monthly===0)?'danger':'default'} value={kpi?.credits_interview_monthly+' / '+kpi?.limit_interview_monthly} loaded={kpi!==undefined} />
                <KpiCard title={'Extra Interview credits'} value={kpi?.credits_interview_extra} loaded={kpi!==undefined} />
            </>
            :(kpi?.type==='Avatar Interviews PRO')&&
            <>
                <KpiCard title={'Monthly Interview credits'} color={(kpi?.credits_interview_monthly===null || kpi?.credits_interview_monthly===0)?'danger':'default'} value={kpi?.credits_interview_monthly+' / '+kpi?.limit_interview_monthly} loaded={kpi!==undefined} />
                <KpiCard title={'Extra Interview credits'} value={kpi?.credits_interview_extra} loaded={kpi!==undefined} />
                </>
            }
            </div>
            </>
          }
        </>
        }
        {merchantFeatures.consumer_portal &&
          <>
            <Divider />
            <KpiCard title="Coaching portal URL" size='md' value={xMojito.merchant.frontend_domain} loaded type='link' />
          </>
        }
      </PopoverContent>
    </Popover>
    :<></>
  )
}
