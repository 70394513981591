'use client'
import MenuLinks from '@jobmojito/admin-app/components/navigation/MenuLinks/MenuLinks'
import MerchantDropdown from '@jobmojito/admin-app/components/navigation/MerchantDropdown'
import ProfileCard from '@jobmojito/admin-app/components/navigation/ProfileCard'
import StatusPlatform from '@jobmojito/admin-app/components/navigation/StatusPlatform'
import { HeaderMerchantProfile } from '@jobmojito/admin-app/types/collection'
import {
  NavbarContent,
  NavbarMenu,
  NavbarMenuToggle,
  Navbar as NextUiNavbar,
} from '@nextui-org/navbar'
import { useState } from 'react'
import LanguageSelector from '@jobmojito/components/core/LanguageSelector'
import {
  findLanguage,
  language,
  languagesSorted,
} from '@jobmojito/admin-app/utils/data/platform-languages'

export default function TopNavbar({
  xMojito,
  merchantLogo,
}: {
  xMojito: HeaderMerchantProfile
  merchantLogo: React.JSX.Element
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  return (
    <NextUiNavbar
      maxWidth={'full'}
      className={'text-base bg-white'}
      classNames={{ wrapper: 'px-4' }}
      isBordered
      onMenuOpenChange={setIsMenuOpen}
      isMenuOpen={isMenuOpen}
    >
      <div className={'flex-none'}>
        <div className="flex flex-row gap-3 max-w-64 items-center">
          <div>{merchantLogo}</div>
          <div className="text-3xl text-black font-thin">Admin</div>
        </div>
      </div>
      <NavbarContent
        className="hidden lg:flex flex-row ms-auto grow gap-6"
        justify={'end'}
      >
        <StatusPlatform xMojito={xMojito} />
        <MerchantDropdown xMojito={xMojito} />
        <LanguageSelector
            languages={languagesSorted.filter((l) =>
              l.enable_admin_interface===true
            )}
          />
        <ProfileCard xMojito={xMojito} />
      </NavbarContent>

      <NavbarMenuToggle
        aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
        className="lg:hidden"
      />
      <NavbarMenu>
        <MenuLinks onClick={closeMenu} xMojito={xMojito} />
        <div className="flex flex-col mt-5 gap-5">
          <MerchantDropdown xMojito={xMojito} />
          <LanguageSelector
            languages={languagesSorted.filter((l) =>
              l.enable_admin_interface===true
            )}
          />
          <ProfileCard xMojito={xMojito} menuLinkOnClick={closeMenu} />
        </div>
      </NavbarMenu>
    </NextUiNavbar>
  )
}
