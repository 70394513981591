'use client'
import MenuLinks from '@jobmojito/admin-app/components/navigation/MenuLinks/MenuLinks'
import { HeaderMerchantProfile } from '@jobmojito/admin-app/types/collection'
import { NavbarContent, Navbar as NextUiNavbar } from '@nextui-org/navbar'
import { useState } from 'react'

export default function Sidebar({
  xMojito,
}: {
  xMojito: HeaderMerchantProfile
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  return (
    <NextUiNavbar
      id={'sidebar'}
      className={
        'hidden lg:flex text-left bg-white mt-5 z-10 border-r divide-slate-200 fixed top-0 left-0 z-5 w-64 h-screen items-start'
      }
      classNames={{ wrapper: 'max-md:px-3' }}
      isBordered
      onMenuOpenChange={setIsMenuOpen}
      isMenuOpen={isMenuOpen}
    >
      <NavbarContent className="flex flex-col grow pt-16 items-start gap-1">
        <MenuLinks onClick={closeMenu} xMojito={xMojito} />
      </NavbarContent>
    </NextUiNavbar>
  )
}
