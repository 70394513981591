import { Button } from '@nextui-org/button'

export function RegisterStep1({ completedStep, subMerchant }: { completedStep: () => void, subMerchant:boolean}) {
    return (
        <>
        <div className="mt-2 mb-5 gap-5">
            <div className='text-xl text-black font-bold underline'><span className='font-light'>Join</span> Affiliate program</div>
            <div className='mt-2 text-black'>Congratulations on becoming a JobMojito Affiliate!</div>
            <div className='text-black'>In just a few minutes, you'll have your affiliate links and earn up to 30% in commissions.</div>
            <div className='mt-5'>Please follow the onboarding checklist, and should you have any queries, don't hesitate to contact us via the <a href="https://jobmojito.com/support" target="_blank" className='underline text-primary'>support page</a>.</div>
        </div>
        <Button color='primary' onClick={()=>{ completedStep(); }}>Next</Button>
        </>
    )
}