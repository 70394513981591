'use client'
import heroSvg from '@jobmojito/admin-app/(landing)/landing/images/background-faqs.jpg'
import PageCard from '@jobmojito/admin-app/components/core/PageCard'
import Image from 'next/image'
import React from 'react'
import { MerchantType } from './RegisterMain'
import { RegisterSelector } from './registerSelector'
import { RegisterStep1 } from './registerStep1Affiliate'
import { RegisterStep2 } from './registerStep2'
import { RegisterStep3 } from './registerStep3'
import { StepsComponent } from './steps'

export default function RegisterAffiliate({
  onChange,
  subMerchant,
  name,
  email,
}: {
  onChange: (range: MerchantType) => void
  subMerchant: boolean
  name?: string
  email?: string
}) {
  const [currentStep, setCurrentStep] = React.useState(1)
  const [nextStepAvailable, setNextStepAvailable] = React.useState(true)

  function processNextStep() {
    setCurrentStep(currentStep + 1)
  }

  function processStepBack(step: number) {
    setCurrentStep(step)
  }

  return (
    <div
      className={
        'w-full flex flex-col items-center relative ' +
        (subMerchant === false ? 'mt-[4rem]' : '')
      }
    >
      <Image
        className={'-mt-10 pointer-events-none'}
        alt="Background Image"
        src={heroSvg}
        fill
        style={{
          objectFit: 'contain',
          scale: 1,
        }}
      />
      <PageCard className={'max-w-5xl'} isVisible={!subMerchant}>
        <div className="flex flex-col justify-left text-left gap-4">
          {currentStep === 1 ? (
            <RegisterSelector
              type="affiliate"
              onChange={(type) => {
                onChange(type)
              }}
            />
          ) : (
            <StepsComponent
              currentStep={currentStep}
              changeStep={processStepBack}
              type="affiliate"
            />
          )}

          {currentStep === 1 && (
            <RegisterStep1
              completedStep={() => {
                processNextStep()
              }}
              subMerchant={subMerchant}
            />
          )}
          {currentStep === 2 && (
            <RegisterStep2
              completedStep={() => {
                processNextStep()
              }}
              type="affiliate"
              subMerchant={subMerchant}
              name={name}
              email={email}
            />
          )}
          {currentStep === 3 && <RegisterStep3 />}
        </div>
      </PageCard>
    </div>
  )
}
