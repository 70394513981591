'use client'
import { HeaderMerchantProfile } from '@jobmojito/admin-app/types/collection'
import getClientSupabase from '@jobmojito/app/utils/getClientSupabase'
import { Avatar } from '@nextui-org/avatar'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/dropdown'
import NextImage from 'next/image'
import { useRouter } from 'next/navigation'
import { useState } from 'react'
import ConfirmationModal from '../core/ConfirmationModal'
import chevronDown from './MenuLinks/assets/chevron_down.svg'

export default function ProfileCard({
  xMojito,
  className = '',
  menuLinkOnClick = () => {},
}: {
  xMojito: HeaderMerchantProfile
  className?: string
  menuLinkOnClick?: () => void
}) {
  const supabase = getClientSupabase()
  const router = useRouter()
  const [confirmationModalShow, setConfirmationModalShow] =
    useState<boolean>(false)

  function signOut() {
    supabase.auth.signOut().then((res) => {
      menuLinkOnClick()
      setConfirmationModalShow(false)
      window.location.href = '/login'
    })
  }

  function goToProfile() {
    menuLinkOnClick()
    router.push('/profile')
  }

  function goToSupport() {
    menuLinkOnClick()
    router.push('/support')
  }

  return (
    <>
      <Dropdown placement="bottom-end" className={className}>
        <DropdownTrigger>
          <div className={'flex flex-row cursor-pointer'}>
            <Avatar
              className={'inline-block shrink-0'}
              // @ts-ignore
              src={xMojito?.profile?.avatar_url}
            />

            <div className={'ml-3 whitespace-nowrap'}>
              <div className={'font-bold text-sm'}>
                {xMojito?.profile?.name}
              </div>
              <div className="text-sm font-light">
                {xMojito?.profile?.email}
              </div>
            </div>
            <NextImage
              src={chevronDown}
              alt={'dropdown'}
              className={'h-7 w-7 ms-1 mt-1 self-start'}
            />
          </div>
        </DropdownTrigger>
        <DropdownMenu aria-label="Profile Actions" variant="flat">
          <DropdownItem onPress={goToProfile} key="profile">
            My Profile
          </DropdownItem>
          <DropdownItem onPress={goToSupport} key="profile">
            Support
          </DropdownItem>
          <DropdownItem
            onPress={() => setConfirmationModalShow(true)}
            key="logout"
            color="danger"
          >
            Log Out
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <ConfirmationModal
        isOpen={confirmationModalShow}
        event={signOut}
        onClose={() => setConfirmationModalShow(false)}
      >
        Do you want to log out?
      </ConfirmationModal>
    </>
  )
}
