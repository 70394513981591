'use client'
import React from 'react'

import RegisterAffiliate from '@jobmojito/admin-app/components/registration/RegisterAffiliate'
import RegisterCoaching from '@jobmojito/admin-app/components/registration/RegisterCoaching'
import RegisterInterview from '@jobmojito/admin-app/components/registration/RegisterInterview'
import RegisterWhiteLabel from '@jobmojito/admin-app/components/registration/RegisterWhiteLabel'

export type MerchantType =
  | 'affiliate'
  | 'interview'
  | 'preparation'
  | 'coaching'

export default function RegisterMain({
  type = 'interview',
  subMerchant,
  name,
  email,
}: {
  type?: MerchantType
  subMerchant: boolean
  name?: string
  email?: string
}) {
  const [currentType, setCurrentType] = React.useState<MerchantType>(type)
  return (
    <>
      {currentType === 'affiliate' && (
        <RegisterAffiliate
          onChange={(newType: MerchantType) => setCurrentType(newType)}
          subMerchant={subMerchant}
          name={name}
          email={email}
        />
      )}
      {currentType === 'interview' && (
        <RegisterInterview
          onChange={(newType: MerchantType) => setCurrentType(newType)}
          subMerchant={subMerchant}
          name={name}
          email={email}
        />
      )}
      {currentType === 'preparation' && (
        <RegisterWhiteLabel
          onChange={(newType: MerchantType) => setCurrentType(newType)}
          subMerchant={subMerchant}
          name={name}
          email={email}
        />
      )}
      {currentType === 'coaching' && (
        <RegisterCoaching
          onChange={(newType: MerchantType) => setCurrentType(newType)}
          subMerchant={subMerchant}
          name={name}
          email={email}
        />
      )}
    </>
  )
}
